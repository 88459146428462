import { graphql } from "gatsby"
import React from "react"
import PropTypes from "prop-types"
import Plx from "react-plx"
import windowSize from "react-window-size"
import { Link } from "gatsby"
import Layout from "../../layouts"
import Container from "../../components/container"
import HeroContainer from "../../components/containers/hero"
import SEO from "../../components/seo"
import Header from "../../components/header"
import Footer from "../../components/footer"
import { SkipNavTarget } from "../../components/shared/components/SkipNav"
import LinkToParentPage from "../../components/link-to-parent-page"
import ContentBlock from "../../components/content-block"
import gatsbyDaysLogo from "!raw-loader!svgo-loader?{ 'plugins': [{ 'collapseGroups': false }, { 'removeTitle': false }, { 'cleanupIDs': false }] }!../../assets/gatsby-days-logo.svg"
import Metaball from "../../components/metaball"

import { rhythm } from "../../utils/typography"

const styles = theme => ({
  sectionHeader: {
    color: theme.colors.lilac,
    fontFamily: theme.fonts.heading,
    fontSize: theme.fontSizes[1],
    textTransform: `uppercase`,
    letterSpacing: theme.letterSpacings.tracked,
    fontWeight: `normal`,
    marginTop: theme.space[10],
  },
})

class GatsbyDaysDetailsPage extends React.Component {
  render() {
    const { contentfulPage, footer } = this.props.data
    const { eventSections, title } = contentfulPage
    const event = eventSections[0] || {}
    const { date, location, venue, year, time, description } = event

    const clientHeight = this.props.windowHeight || 1

    return (
      <Layout pathname={this.props.location.pathname}>
        <SEO contentfulPage={contentfulPage} url={this.props.location.href} />
        <Header />
        <main css={{ position: `relative`, width: `100%` }}>
          <SkipNavTarget />
          <Plx
            animateWhenNotInViewport
            parallaxData={[
              {
                duration: clientHeight * 1.5,
                offset: -clientHeight,
                properties: [
                  {
                    endValue: 200,
                    property: `translateY`,
                    startValue: 0,
                  },
                ],
                start: `self`,
              },
            ]}
          >
            <Metaball />
          </Plx>
          <HeroContainer>
            <Container>
              <div css={{ position: `relative`, width: `100%` }}>
                <Plx
                  animateWhenNotInViewport
                  parallaxData={[
                    {
                      duration: clientHeight * 1.5,
                      offset: -clientHeight,
                      properties: [
                        {
                          endValue: -50,
                          property: `translateY`,
                          startValue: 0,
                        },
                      ],
                      start: `self`,
                    },
                  ]}
                >
                  <div
                    css={theme => ({
                      display: `flex`,
                      flexDirection: `column`,
                      marginBottom: rhythm(2.5),
                      [theme.mediaQueries.desktop]: {
                        alignItems: `flex-end`,
                        flexDirection: `row`,
                        justifyContent: `space-between`,
                      },
                    })}
                  >
                    <div css={{ maxWidth: rhythm(25) }}>
                      {contentfulPage.parentPage && (
                        <LinkToParentPage contentfulPage={contentfulPage} />
                      )}
                      <h1
                        css={theme => ({
                          color: theme.colors.gatsby,
                          fontSize: theme.fontSizes[6],
                          fontWeight: 800,
                        })}
                      >
                        {title}
                      </h1>
                      <h2
                        css={theme => ({
                          fontSize: theme.fontSizes[9],
                          fontWeight: 800,
                          letterSpacing: `-0.03em`,
                          lineHeight: 1.1,
                          marginTop: rhythm(1.5),
                          marginBottom: rhythm(2.5),
                          [theme.mediaQueries.desktop]: {
                            fontSize: `3.25rem`,
                          },
                        })}
                      >
                        {date}
                        <br />
                        {location}
                      </h2>

                      <div
                        css={{
                          maxWidth: `680px`,
                          h3: {
                            display: `none`,
                          },
                        }}
                        dangerouslySetInnerHTML={{
                          __html: description.childMarkdownRemark.html,
                        }}
                      />
                    </div>
                    <div
                      css={theme => ({
                        h5: {
                          ...styles(theme).sectionHeader,
                        },
                        p: {
                          fontFamily: theme.fonts.heading,
                          color: theme.colors.grey.copy,
                        },
                      })}
                    >
                      <h5>Venue</h5>
                      <p>
                        {venue} {venue && <br />} {location}
                      </p>
                      <h5>Date</h5>
                      <p>
                        {date}, {year}
                        <br />
                        {time}
                      </p>
                    </div>
                  </div>
                </Plx>
              </div>
            </Container>
          </HeroContainer>

          {event.videos && event.videos.length > 0 ? (
            <Container>
              <div css={styles.container}>
                {` `}
                <div css={styles.container} />
                <ul
                  css={theme => ({
                    margin: 0,
                    listStyle: `none`,
                    [theme.mediaQueries.tablet]: {
                      display: `flex`,
                      flexWrap: `wrap`,
                      justifyContent: `space-between`,
                    },
                  })}
                >
                  {event.videos.map(video => {
                    const { page } = video

                    if (!page) {
                      return null
                    }

                    const { title, contentBlocks, slug } = page[0]
                    const contentBlock = contentBlocks[0]

                    return (
                      <li
                        key={contentBlock.id}
                        css={theme => ({
                          flex: `0 0 48%`,
                          [theme.mediaQueries.desktop]: {
                            flex: `0 0 31%`,
                            width: `100%`,
                          },
                        })}
                      >
                        <Link
                          to={`/${slug}`}
                          title="Permalink"
                          css={{
                            display: `block`,
                            "&&": {
                              marginBottom: 40,
                            },
                            textDecoration: `none`,
                          }}
                        >
                          <div>
                            <ContentBlock contentBlock={contentBlock} />
                          </div>

                          <div
                            css={{
                              marginTop: 8,
                              fontWeight: `bold`,
                            }}
                          >
                            {title}
                          </div>
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </Container>
          ) : null}
          <Container
            css={theme => ({
              "> div": {
                display: `flex`,
                marginTop: rhythm(2),
                flexDirection: `column`,
                [theme.mediaQueries.desktop]: {
                  flexDirection: `row`,
                  alignItems: `center`,
                },
              },
            })}
          >
            <div
              dangerouslySetInnerHTML={{ __html: gatsbyDaysLogo }}
              css={theme => ({
                maxWidth: rhythm(6),
                marginBottom: rhythm(1),
                marginRight: rhythm(3),
                flexShrink: 0,
                flexGrow: 0,
                svg: {
                  width: `100%`,
                  height: `auto`,
                },
                [theme.mediaQueries.desktop]: {
                  marginBottom: 0,
                },
              })}
            />
            <div
              css={{
                display: `flex`,
                alignItems: `center`,
                "p:last-of-type": {
                  margin: 0,
                },
              }}
              dangerouslySetInnerHTML={{
                __html: footer.body.childMarkdownRemark.html,
              }}
            />
          </Container>
        </main>
        <Footer />
      </Layout>
    )
  }
}

GatsbyDaysDetailsPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  dimensions: PropTypes.object,
  windowHeight: PropTypes.number,
}

export default windowSize(GatsbyDaysDetailsPage)

export const pageQuery = graphql`
  query ContentfulGatsbyDaysDetailsPage($id: String!) {
    contentfulPage(id: { eq: $id }) {
      name
      title
      parentPage {
        name
        slug
        parentPage {
          name
          slug
        }
      }
      ...ContentBlocks
      ...SocialMediaImage
      eventSections {
        id
        title
        date
        year
        time
        location
        venue
        description {
          childMarkdownRemark {
            html
          }
        }
        videos {
          page {
            title
            name
            slug
            ...ContentBlocks
          }
        }
      }
    }
    footer: contentfulLongFormBlock(
      contentfulid: { eq: "gatsbyDaysPage_footer" }
    ) {
      contentfulid
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
